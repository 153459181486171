<template>
  <div class="main">
    <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/warning/maturity.png" alt="">
    <div class="text">您想要的小闪闪已被人抢走了！</div>
  </div>
</template>

<script>
  // 钻石被购买
  export default {
    name: 'regret',
  }
</script>

<style scoped lang="scss">
  .main{
    height: calc(100vh - 360px);
    min-height: 512px;
    width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;

    img{
      width: 65px;
      height: 66px;
      margin-bottom: 32px;
    }

    .text{
      font-size: 15px;
      color: #999999;
      font-weight: 500;
    }
  }
</style>
